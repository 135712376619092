
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { VCombobox, VAutocomplete } from "vuetify/lib"
import { AdminApi } from "@evercam/shared/api/adminApi"
export default {
  components: {
    VAutocomplete,
    ValidationObserver,
    ValidationProvider,
    VCombobox,
  },
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: () => null,
    },
    resource: {
      type: String,
      default: () => "",
    },
    rules: {
      type: [String, Object],
      default: () => "",
    },
    vid: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: () => "",
    },
    listItems: {
      type: Array,
      default: () => [],
    },
    menuProps: {
      type: Object,
      default: () => ({}),
    },
    provider: {
      type: Function,
      default: async () => {
        return {
          items: [],
        }
      },
    },
    providerParams: {
      type: Function,
      default: (val) => {
        return { term: val }
      },
    },
    searchOnFocus: {
      type: Boolean,
      default: false,
    },
    searchOnMounted: {
      type: Boolean,
      default: false,
    },
    isCombobox: {
      type: Boolean,
      default: false,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      itemSearch: null,
    }
  },
  computed: {
    selectedItem: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
  watch: {
    async itemSearch(val) {
      if (this.isLoading) return
      if (!this.searchOnFocus && (!val || val.length < 3)) {
        return
      }
      this.loadItems(val)
    },
    search: {
      async handler(val) {
        if (val) {
          await this.loadItems(val)
          this.$emit("on-search-item", this.items)
        }
      },
      immediate: true,
    },
    listItems: {
      handler(val) {
        this.items = val
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.searchOnMounted) {
      this.loadItems()
    }
  },
  methods: {
    getItemText(item) {
      return item[this.getProp("item-text", "name")]
    },
    getProp(propName, fallback) {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, propName)) {
        return this.$attrs[propName]
      } else {
        return fallback
      }
    },
    async loadItems(val) {
      this.isLoading = true
      try {
        let response
        let params = this.providerParams(val)
        if (this.resource) {
          params.resource = this.resource
          response = await AdminApi.search.search({
            params,
          })
        } else {
          response = await this.provider({ params })
        }
        this.items = response?.items || response
        // this.$forceUpdate()
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: this.$attrs?.label,
          }),
          error,
        })
      }
      this.isLoading = false
    },
    onBlur() {
      if (!this.autoSelect || !this.itemSearch || !this.$attrs.filter) {
        return
      }

      const item = this.listItems.find((item) =>
        this.$attrs.filter(item, this.itemSearch)
      )

      if (!item) {
        return
      }

      this.selectedItem = item
    },
    onChange(value) {
      this.$emit("change", value)
    },
    onFocus() {
      if (this.searchOnFocus) {
        this.loadItems()
      }
    },
    onKeydown(event) {
      this.$emit("keydown", event)
    },
  },
}
